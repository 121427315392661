<template>
    <v-app-bar
    color="primary"
    dark
    flat
    fixed
    >
        {{app_title}}
        <v-spacer></v-spacer>
        <v-btn icon @click="GoToURL('scan')">
        <v-icon>crop_free</v-icon>
        </v-btn>
        <v-btn icon @click="GoToURL('help')"><v-icon>help</v-icon></v-btn>
    </v-app-bar>
</template>
<script>
    export default {
        props: ['app_title'],
        methods: {
           GoToURL (flag) {
                this.$router.push({name: flag})
            }
        }
    }
</script>
