<template>
  <div>
  	<app-toolbarcustom app_title="Tentang Kami" app_back="Y"></app-toolbarcustom>
    <div class="d-flex">
      <v-checkbox
        label="Readonly"
      ></v-checkbox>
    </div>

    <v-expansion-panels
      v-model="panel"
    >
      <v-expansion-panel
       v-for="item in items"
      :key="`${item.aboutus_id}`">
        <v-expansion-panel-header>{{item.aboutus_title}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          {{item.aboutus_content}}
        </v-expansion-panel-content>
      </v-expansion-panel>
     </v-expansion-panels>

  </div>
</template>
<script>
  export default {
    data: () => ({
      panel: [0],
      items: []
    }),
    created () {
        this.AboutUs()
    },
    methods: {
        AboutUs() {
            let formData = new FormData()
            formData.append('order_by', 'aboutus_sortno')
            formData.append('order_type', 'ASC')
            this.$axios.post(this.$functions.SafeURL('apiListAboutUs', ''),formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            })
            .then(response => {
                this.items = response.data
            })
            .catch(e => {
                alert(e)
            })
        },
    }
  }
</script>