<template>
  <div id="app">
    <v-app id="inspire" v-if="login">
    <v-fade-transition mode="out-in">
        <router-view></router-view>
     </v-fade-transition>
     <router-view name="bottomnavigation"></router-view>
    </v-app>
    <v-app id="inspire" v-else>
      <app-login></app-login>
    </v-app>
  </div>
</template>
<script>
    export default {
        data: () => ({
            login: false
        }),
        created () {
          this.CheckLogin()
        },
        methods: {
          CheckLogin() {
              let usersid =  this.$functions.UsersID()
              if (usersid !== null) {
                this.login = usersid
              }
          }
        }
    }
</script>