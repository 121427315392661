<template>
  <v-container>
      <v-row align="center" justify="center">
          <v-col cols="12" sm="10">
            <v-card class="elevation-6 mt-10">
             <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row>
                    <v-col cols="12" md="12" class="mb-n10">
                      <div style="text-align: center;"><img src="../../public/logo.jpg" width="47%"></div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card-text class="mt-12">
                        <h4
                          class="text-center mb-n10"
                        >{{app_title}}</h4>
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="8">
                           
                          <v-text-field
                            v-model="form.usersid"
                            label="ID"
                            outlined
                            dense
                            color="primary"
                            autocomplete="false"
                            class="mt-16"
                          />
                          <v-text-field
                            v-model="form.userspassword"
                            label="Password"
                            outlined
                            dense
                            color="primary"
                            autocomplete="false"
                            :type="form.userspassword_hint?'text':'password'"
                            :append-icon="form.userspassword_hint?'visibility_off':'visibility'"
                            @click:append="form.userspassword_hint =!form.userspassword_hint"
                            @keyup.enter="ProcessLogin"
                          />
                            <!-- <v-row>
                              <v-col cols="12" sm="5">
                                    <span class="caption primary--text">Lupa Password</span>
                              </v-col>
                            </v-row> -->
                          <v-btn color="primary" dark block tile @click="ProcessLogin">Log in</v-btn>
                     
                          </v-col>
                        </v-row>  
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" md="6" class="primary rounded-bl-xl" >
                    <div style="  text-align: center; padding: 180px 0;">
                      <v-card-text class="white--text" >
                        <h3 class="text-center ">Belum bisa akses login?</h3>
                        <h6
                          class="text-center"
                        >Hubungi administrator PT Focus</h6>
                      </v-card-text>
                      <div class="text-center">
                        <!-- <v-btn tile outlined dark @click="step++">AKSES</v-btn> -->
                      </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item :value="2">
                  <v-row >
                    <v-col cols="12" md="6" class="primary rounded-br-xl">
                     <div style="  text-align: center; padding: 180px 0;">
                      <v-card-text class="white--text" >
                        <h3 class="text-center ">Alredy Signed up?</h3>
                        <h6
                          class="text-center"
                        >Log in to your account so you can continue building and<br>  editing your onboarding flows</h6>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn tile outlined dark @click="step--">Log in</v-btn>
                      </div>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-card-text class="mt-12">
                        <h4
                          class="text-center"
                        >Sign Up for an Account</h4>
                        <h6
                          class="text-center  grey--text "
                        >Let's get you all set up so you can start creatin your <br>
                        first onboarding experiance</h6>
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="8">
                           <v-row>
                           <v-col cols="12" sm="6">
                            <v-text-field
                            label="First Name"
                            outlined
                            dense
                            color="primary"
                            autocomplete="false"
                           class="mt-4"
                          />
                           </v-col>
                           <v-col cols="12" sm="6">
                            <v-text-field
                            label="Last Name"
                            outlined
                            dense
                            color="primary"
                            autocomplete="false"
                           class="mt-4"
                          />
                           </v-col>
                           </v-row>
                          <v-text-field
                            label="Email"
                            outlined
                            dense
                            color="primary"
                            autocomplete="false"
                          />
                          <v-text-field
                            label="Password"
                            outlined
                            dense
                            color="primary"
                          autocomplete="false"
                           type="password"
                          
                          />
                            <v-row>
                              <v-col cols="12" sm="7">
                                <v-checkbox
                       
                        label="I Accept AAE"
                        class="mt-n1"
                        color="primary"
                      > </v-checkbox>
                              </v-col>
                              <v-col cols="12" sm="5">
                                <span class="caption primary--text ml-n4">Terms &Conditions</span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>  
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
      </v-row>
      <app-snackbar :dialog="snackbar.dialog" :color="snackbar.color" :text="snackbar.text" :timeout="snackbar.timeout" @close="snackbar.dialog = false"></app-snackbar>
  </v-container>
</template>

<script>
  export default {
   data: () => ({
    app_title: 'Login in ke Akun Anda',
    step: 1,
    form: {
        usersid: '',
        userspassword: '',
        userspassword_hint: false,
    },
    confirm: {
        dialog: false,
        title: "",
        text: "Ok",
    },
    snackbar: {
        dialog: false,
        color: "primary",
        text: "",
        timeout: 3000,
    },
  }),
  methods: {
    ProcessLogin() {
        this.app_title = 'Loading...'
        let formdata = {
            url_type: 'login',
            users_id: this.form.usersid,
            users_password: this.form.userspassword,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWriteUsers'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback === 'Y') {
                    this.UsersDetail(this.form.usersid,feedback[0].session_time)
                } else {
                    this.SnackBar(true,'error',feedback[0].feedback,3000)
                }
            }
            this.app_title = 'Login in ke Akun Anda'
        })
        .catch(e => {
            this.SnackBar(true,'error',e,3000)
        })
    },
    UsersDetail (id,time) {
        localStorage.setItem('local_tim_users', id)
        let formdata = {
            users_id: id,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListUsers'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback === 'Y') {
                    localStorage.setItem('local_tim_session_timeout', time)
                    localStorage.setItem('local_tim_login', 'Y')
                    localStorage.setItem('local_tim_usersdetail', JSON.stringify(feedback))
                    this.SnackBar(true, 'success', 'Login is Successfully', 0)
                    setTimeout(function(){
                      window.location.href = '/'
                    }, 1000)
                } else {
                    this.SnackBar(true,'error',feedback[0].feedback,3000)
                }
            }
            this.app_title = 'Login in ke Akun Anda'
        })
        .catch(e => {
            this.SnackBar(true,'error',e,3000)
        })
    },
    SnackBar(dialog, color, text, timeout) {
        this.snackbar = {
            dialog: dialog,
            color: color,
            text: text,
            timeout: timeout,
        }
        this.confirm.dialog 	= false
        this.confirm.text 		= "Ok"
    },
  },
  props: {
    source: String
  }     
  }
</script>
<style scoped>
.v-application .rounded-bl-xl {
    border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
    border-bottom-right-radius: 300px !important;
}
</style>