<template>
	<div>
	<app-toolbarcustom app_title="Quran" app_url="sharia" app_icon="clear"></app-toolbarcustom>
	<v-container>
		<v-layout row wrap justify-center align-center>
			<v-btn color="success" @click="OpenQuran" block round>Klik untuk membaca Quran</v-btn>
		</v-layout>
	</v-container>
	</div>
</template>
<script>
	export default {
		methods: {
			OpenQuran() {
				window.open('https://litequran.net')
			}
		}
	}
</script>
