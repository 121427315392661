<template>
    <div>
    <app-toolbarcustom app_title="Sharia" app_back="Y"></app-toolbarcustom>
    <v-card flat>
        <v-list two-line>
            <v-subheader></v-subheader>
            <template v-for="(item, index) in posts">
                <v-list-item
                  :key="item.icon"
                  @click="GoTo(item.url)"
                >
                  <v-list-item-avatar>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{item.text}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                v-if="index + 1 < posts.length"
                :key="index"
              ></v-divider>
           </template>
        </v-list>
    </v-card>
    </div>
</template>
<script>
    export default {
        data: () => ({
            loading: false,
            posts: [
                {icon: 'book', text: 'Quran', description: 'Baca Quran', url:'/sharia/quran'},
                {icon: 'pan_tool', text: 'Doa-doa', description: 'Doa-doa', url:'/sharia/doa'},
                {icon: 'wb_sunny', text: 'Dzikir Pagi', description: 'Dzikir Pagi', url:'/sharia/dzikirpagi'},
                {icon: 'star', text: 'Dzikir Sore', description: 'Dzikir Sore', url:'/sharia/dzikirsore'},
                {icon: 'all_inclusive', text: 'Asmaul Husna', description: '99 nama-nama Allah Ta`ala', url:'/sharia/asmaulhusna'},
                {icon: 'watch_later', text: 'Jadwal Sholat', description: 'Jadwal Sholat hari ini', url:'/sharia/jadwalsholat'}
            ]
        }),
        methods: {
            GoTo (url) {
               this.$router.push(url)
            }
        }
    }
</script>