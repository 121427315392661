import * as api from '../../config.json'
import * as packages from '../../package.json'
export default {
    // Version
    AppVersion () {
        const appversion = packages.version
        return appversion
    },
    //UsersID get local storage users id
    UsersID () {
        let usersid = localStorage.getItem('local_tim_users')
        return usersid
    },
    UsersType () {
        let usersdetail = JSON.parse(localStorage.getItem('local_tim_usersdetail'))
        let userstype   = usersdetail[0].users_type
        return userstype
    },
    //SafeURL curl json api
    SafeURL (url, params) {
        const hosts = api.api_connection
        const keys = api.api_key
        const secret = api.api_secret
        let loginuser = this.UsersID()
        let newurl
        if (params !== '') {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&${params}&login_user=${loginuser}`
        } else {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&login_user=${loginuser}`
        }
            return newurl
    },
    TrueOrFalse (flag) {
        let returns = false
        if (flag === 'Y') {
            returns = true
        } else if (flag === 'F'){
            returns = true
        }
        return returns
    },
    Access (flag) {
        let returns = false
        if (flag === 1) {
            returns = true
        } else if (flag == true){
            returns = true
        }
        return returns
    },
    Method (flag) {
        let returns = false
        if (flag === 'POST') {
            returns = true
        }
        return returns
    },
    Gender(S) {
        let returns = true
        if (S === 'F') {
            returns = false
        }
        return returns
    },
     //FormatDateTime format date MM/DD/YYYY H:i:s
     FormatDateTimeSession (dates) {
        let today = new Date(dates)
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        let hh = today.getHours()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        if (hh < 10) {
            hh = '0' + hh
        }
        today = dd + '-' + mm + '-' + yyyy
        return today
    },
    //Format New Date
    FormatNewDate (dates) {
        let today = new Date(dates)
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    Level(L) {
        let returns = 'High'
        if (L === 'L') {
            returns === 'Low'
        }
        return returns
    },
    UrlPOST (url) {
        const hosts  = api.api_connection
        let newurl   = `${hosts}/${url}`
        return newurl
    },
    ParamPOST (param) {
        let key         = api.api_key
        let secret      = api.api_secret
        let login_user  = this.UsersID()
        var combine      = JSON.stringify({ key: key, secret: secret, login_user: login_user, "data": param })
        return combine
    },
    //FormatDateFull format date MM/DD/YYYY H:i:s
    FormatDateFull (date) {
        if (!date) return null
        const  dates             = date.split('T')
        const [year, month, day] = dates[0].split('-')
        const newmonth           = this.ShortMonthNameEng(month) 
        return `${newmonth} ${day} ${year}`
    },
    //ShortMonthNameEng MonthNameEnglish
    ShortMonthNameEng (months) {
        let monthsindo = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    //ShortMonthNameEng MonthNameEnglish
    ShortMonthNameIndo (months) {
        let monthsindo = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    //Today get the day now
    Today () {
        let today = new Date()
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    DayNameIndo (date) {
        var days = ['Ahad', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
        var d = new Date(date)
        var dayname = days[d.getDay()]
        return dayname
    },
    //FormatDateFull format date MM/DD/YYYY
    FormatDateIndoWithDayName (date) {
        if (!date) return null
        const dates              = date
        const [year, month, day] = dates.split('-')
        const newmonth           = this.ShortMonthNameIndo(month)
        var dayname              = this.DayNameIndo(date)
        return `${dayname}, ${newmonth} ${day} ${year}`
    },
}