import Vue from 'vue'
import Router from 'vue-router'
import BottomNavigation from '@/components/com_bottom_default.vue'
import ModDashboard from '../views/modul/mod_dashboard/dashboard'
import ModAboutUs from '../views/modul/mod_aboutus/aboutus'
import ModAccount from '../views/modul/mod_account/account'
//Modul Help
import ModHelp from '../views/modul/mod_help/help.vue'
//Modul Help
import ModHelpFAQ from '../views/modul/mod_help/help_faq.vue'
//Modul Policy
import ModPolicy from '../views/modul/mod_policy/policy.vue'
//Modul PolicyPA
import ModPolicyPA from '../views/modul/mod_policy/policy_pa.vue'
//Modul Sharia
import ModSharia from '../views/modul/mod_sharia/sharia.vue'
//Modul Sharia Doa
import ModShariaDoa from '../views/modul/mod_sharia/sharia_doa.vue'
//Modul Sharia Dzikir Pagi
import ModShariaDzikirPagi from '../views/modul/mod_sharia/sharia_dzikirpagi.vue'
//Modul Sharia Dzikir Sore
import ModShariaDzikirSore from '../views/modul/mod_sharia/sharia_dzikirsore.vue'
//Modul Sharia Asmaul Husna
import ModShariaAsmaulHusna from '../views/modul/mod_sharia/sharia_asmaulhusna.vue'
//Modul Sharia Jadwal Sholat
import ModShariaJadwalSholat from '../views/modul/mod_sharia/sharia_jadwalsholat.vue'
//Modul Sharia Quran
import ModShariaQuran from '../views/modul/mod_sharia/sharia_quran.vue'
//Modul Todo
import ModTodo from '../views/modul/mod_todo/todo.vue'
//Modul Employee
import ModEmployee from '../views/modul/mod_employee/employee.vue'

Vue.use(Router)

export default new Router({
  // hashbang: false,
  // history: true,
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    //Modul Dashboard
    {
      path: '/',
      name: 'home',
      components: {
        default: ModDashboard,
        bottomnavigation: BottomNavigation
      }
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      components: {
        default: ModAboutUs,
        bottomnavigation: BottomNavigation
      }
    },
    {
      path: '/account',
      name: 'account',
      components: {
        default: ModAccount,
        bottomnavigation: BottomNavigation
      }
    },
     //Modul Help
    {
      path: '/help',
      name: 'help',
      components: {
        default: ModHelp,
        bottomnavigation: false
      }
    },
    //Modul Help FAQ
    {
      path: '/help/faq',
      name: 'helpfaq',
      components: {
        default: ModHelpFAQ,
        bottomnavigation: false
      }
    },
    //Modul Policy
    {
      path: '/policy',
      name: 'policy',
      components: {
        default: ModPolicy,
        bottomnavigation: false
      }
    },
    //Modul Policy PA
    {
      path: '/policy/pa',
      name: 'policypa',
      components: {
        default: ModPolicyPA,
        bottomnavigation: false
      }
    },
    //Modul Sharia
    {
      path: '/sharia',
      name: 'sharia',
      components: {
        default: ModSharia,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Doa
    {
      path: '/sharia/doa',
      name: 'shariadoa',
      components: {
        default: ModShariaDoa,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Dzikir Pagi
    {
      path: '/sharia/dzikirpagi',
      name: 'dzikirpagi',
      components: {
        default: ModShariaDzikirPagi,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Dzikir Sore
    {
      path: '/sharia/dzikirsore',
      name: 'dzikirsore',
      components: {
        default: ModShariaDzikirSore,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Asmaul Husna
    {
      path: '/sharia/asmaulhusna',
      name: 'asmaulhusna',
      components: {
        default: ModShariaAsmaulHusna,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Jadwal Sholat
    {
      path: '/sharia/jadwalsholat',
      name: 'jadwalsholat',
      components: {
        default: ModShariaJadwalSholat,
        bottomnavigation: BottomNavigation
      }
    },
    //Modul Sharia Quran
    {
      path: '/sharia/quran',
      name: 'quran',
      components: {
        default: ModShariaQuran,
        bottomnavigation: false
      }
    },
    //Modul Todo
    {
      path: '/todo',
      name: 'todo',
      components: {
        default: ModTodo,
        bottomnavigation: false
      }
    },
    //Modul Todo
    {
      path: '/employee',
      name: 'employee',
      components: {
        default: ModEmployee,
        bottomnavigation: false
      }
    },
  ]
})
