<template>
    <div>
        <v-snackbar
            v-model	    = "dialogsnackbar"
            :timeout	= "timeout"
            :color		= "color === 'error'?'fourth':'success'"
            rounded	    = "pill"
            top
            style 	    = "z-index: 9999"
            >
            <span v-if="text.length > 30">Error</span>
            <span v-else>{{ text }}</span>
            <template>
            <v-btn dark text @click.native="remarks.dialog = true" small v-if="color === 'error'">Open</v-btn>
            <v-btn dark text @click.native="dialogsnackbar = false" small>Close</v-btn>
            </template>
        </v-snackbar>
        <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="color === 'error'">
            <v-card>
                <v-toolbar dark color="fourth" dense>
                <v-btn icon dark @click="remarks.dialog = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Error Messages</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-2 ml-n2">
                    <div class="subheading font-weight-bold" v-html="text"></div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
  export default {
    props: {
    dialog: Boolean,
    color: String,
    text: String,
    timeout: Number,
  },
  data: () => ({
	remarks: {
        dialog: false
    },
  }),
  computed: {
    dialogsnackbar: {
        get() {
            return this.dialog
        },
        set(value) {
            if (!value) {
                this.$emit('close')
            }
        }
    }
  },
}
</script>