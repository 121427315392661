<template>
    <div class="dashboard">
        <app-toolbardefault app_title="TIM FOCUS"></app-toolbardefault>
        <!-- Greeting Header-->
        <v-card color="primary" class="mt-10" flat>
            <v-container
                fluid
                dark
            >
                <v-layout row wrap>
                <v-flex xs12>
                    <v-card tile color="primary" class="white--text">
                    <v-card-title primary-title>
                        <div>
                        <div class="title">Assalamualaikum wr wb,</div>
                        <div class="subtitle-1">{{data.users_name}}</div>
                        </div>
                    </v-card-title>
                    </v-card>
                </v-flex>
                </v-layout>
            </v-container>
        </v-card>
        <template>
        <v-layout class="overflow-visible" style="height: 56px;">
            <v-bottom-navigation
            horizontal
            color="primary"
            class="elevation-0"
            >
            <v-btn text @click="GoToURL(row.url)" v-for="row in menu" :key="row.icon">
                <v-icon>{{row.icon}}</v-icon>
                {{row.text}}
            </v-btn>
            </v-bottom-navigation>
        </v-layout>
        </template>

        <template v-if="detail.absence.length > 0">
            <v-card flat>
                <v-list two-line>
                    <v-subheader class="font-weight-bold primary white--text">Absen Hari Ini</v-subheader>
                    <template v-for="(item, index) in detail.absence">
                        <v-list-item
                        :key="item.absence_usersid"
                        @click="OpenAbsence(item.absence_usersid)"
                        >
                        <v-list-item-avatar>
                            <v-img :src="item.absence_users_avaurl"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{item.absence_users_name}}</v-list-item-title>
                            <v-list-item-subtitle>IN: {{item.absence_timein}} OUT:{{item.absence_timeout}}</v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider
                        v-if="index + 1 < detail.absence.length"
                        :key="index"
                    ></v-divider>
                </template>
                </v-list>
            </v-card>
        </template>
        <template v-if="detail.todo.length > 0">
            <v-card flat>
                <v-list three-line>
                    <v-subheader class="font-weight-bold secondary white--text">Todo Hari Ini</v-subheader>
                    <template v-for="(item, index) in detail.todo">
                        <v-list-item
                        :key="item.todo_id"
                        >
                        <v-list-item-avatar>
                            <v-img :src="item.todo_users_avaurl"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{item.todo_users_name}}</v-list-item-title>
                            <v-list-item-subtitle>{{item.todo_desc}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="caption font-weight-bold">
                            {{item.todo_time}}
                        </v-list-item-action>
                        </v-list-item>
                        <v-divider
                        v-if="index + 1 < detail.todo.length"
                        :key="index"
                    ></v-divider>
                </template>
                </v-list>
            </v-card>
        </template>
        <div class="mt-5">&nbsp;</div>
        <v-dialog v-model="absence.dialog" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text"><v-avatar class="mr-2"><v-img :src="data.absence_users_avaurl"></v-img></v-avatar> {{data.absence_users_name}}</v-card-title>
                <v-card-text>
                    <div>
                        <div class="font-weight-bold text-center title">IN: {{data.absence_timein}}</div>
                        <div><iframe :src="data.absence_timein_url" width="100%" height="175" frameborder="0"></iframe></div>
                    </div>
                    <v-divider></v-divider>
                    <div v-if="data.absence_timeout !== '00:00'">
                        <div class="font-weight-bold text-center title">OUT: {{data.absence_timeout}}</div>
                        <div><iframe :src="data.absence_timeout_url" width="100%" height="175" frameborder="0"></iframe></div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    {{$functions.FormatDateIndoWithDayName(data.absence_date)}}
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="absence.dialog = false">{{confirm.close}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog v-model="confirm.dialog" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="WriteTodo('event')">{{confirm.ok}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
        <app-snackbar :dialog="snackbar.dialog" :color="snackbar.color" :text="snackbar.text" :timeout="snackbar.timeout" @close="snackbar.dialog = false"></app-snackbar>
    </div>
</template>
<script>
  export default {
    data: () => ({
        data: {
            users_id: '',
            users_name: '',
            absence_users_name: '',
            absence_users_avaurl: '',
            absence_date: '',
            absence_timein: '',
            absence_timeout: '',
            absence_latitude_in: '',
            absence_longitude_in: '',
            absence_geolocation_in: '',
            absence_latitude_out: '',
            absence_longitude_out: '',
            absence_geolocation_out: '',
            absence_timein_url: '',
            absence_timeout_url: '',
        },
        detail: {
            absence: [],
            todo: [],
        },
        menu: [
            {icon: 'mdi-badge-account-outline', text: 'Kepegawaian', url: 'employee'},
            {icon: 'mdi-calendar', text: 'Todo', url: 'todo'},
            {icon: 'mdi-fingerprint', text: 'Absen', url: 'account'},
        ],
        confirm: {
            dialog: false,
            title: '',
            text: '',
            ok: 'OK',
            close: 'Close',
        },
        absence: {
            dialog: false,
            title: '',
            text: '',
        },
        snackbar: {
            dialog: false,
            color: "primary",
            text: "",
            timeout: 3000,
        },
    }),
    created () {
        this.Initial()
    },
    methods: {
        Initial() {
           let usersdetail = JSON.parse(localStorage.getItem('local_tim_usersdetail'))
           this.data.users_id = usersdetail[0].users_id
           this.data.users_name = usersdetail[0].users_name
           this.Absence(this.data.users_id)
           this.Todo(this.data.users_id)
        },
        GoToURL (flag) {
           this.$router.push({name: flag})
        },
        Absence (id) {
            let formdata = {
                absence_date: this.$functions.Today(),
                absence_leader: id,
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                       this.detail.absence =  feedback
                    } else {
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.SnackBar(true,'error',e,3000)
            })
        },
        Todo (id) {
            let formdata = {
                todo_date: this.$functions.Today(),
                todo_leader: id,
                order_by: 'todo_id',
                order_type: 'DESC',
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListTodo'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                       this.detail.todo =  feedback
                    } else {
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.SnackBar(true,'error',e,3000)
            })
        },
        OpenAbsence(id) {
            this.absence.dialog = true
            let formdata = {
                absence_date: this.$functions.Today(),
                absence_usersid: id,
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                       this.data.absence_users_name = feedback[0].absence_users_name
                       this.data.absence_users_avaurl = feedback[0].absence_users_avaurl
                       this.data.absence_date = feedback[0].absence_date
                       this.data.absence_timein = feedback[0].absence_timein
                       this.data.absence_timeout = feedback[0].absence_timeout
                       this.data.absence_latitude_in = feedback[0].absence_latitude_in
                       this.data.absence_longitude_in = feedback[0].absence_longitude_in
                       this.data.absence_geolocation_in = feedback[0].absence_geolocation_in
                       this.data.absence_latitude_out = feedback[0].absence_latitude_out
                       this.data.absence_longitude_out = feedback[0].absence_longitude_out
                       this.data.absence_geolocation_out = feedback[0].absence_geolocation_out
                       this.data.absence_timein_url = 'https://maps.google.com/maps?q='+feedback[0].absence_latitude_in+','+feedback[0].absence_longitude_in+'&t=&z=19&ie=UTF8&iwloc=&output=embed'
                       this.data.absence_timeout_url = 'https://maps.google.com/maps?q='+feedback[0].absence_latitude_out+','+feedback[0].absence_longitude_out+'&t=&z=19&ie=UTF8&iwloc=&output=embed'
                    } else {
                        this.SnackBar(true,'error',feedback[0].feedback,3000)
                    }
                }
            })
            .catch(e => {
                this.SnackBar(true,'error',e,3000)
            })
        },
        SnackBar(dialog, color, text, timeout) {
            this.snackbar = {
                dialog: dialog,
                color: color,
                text: text,
                timeout: timeout,
            }
            this.confirm.dialog 	= false
            this.confirm.ok 		="Ok"
        },
    }
  }
</script>