<template>
    <v-dialog v-model="dialog_confirmation" max-width="490" hide-overlay persistent>
        <v-card>
            <v-card-title class="primary white--text">{{text}}</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
            	<v-spacer></v-spacer>
            	<v-btn id="dialog" color="success"
            		   :disabled   = "dialog_ok_disabled"
      				   @click      = "OK">{{dialog_ok_text}}</v-btn>
                <v-btn color="error" @click.stop="dialog_confirmation=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
    	props: ['visible','text','ok_text','ok_disabled'],
    	data: () => ({
    		dialog_ok_text: 'Ok',
    		dialog_ok_disabled: false
    	}),
        watch: {
            dialog_confirmation () {
                setTimeout(function(){
                    document.getElementById('dialog').focus()
                }, 500)
            },
            ok_text (value) {
            	alert(value)
            }
        },
        computed: {
            dialog_confirmation: {
                get() {
                    return this.visible
                },
                set(value) {
                    if (!value) {
                        this.$emit('close')
                    }
                }
            }
        },
        methods: {
        	OK () {
        		this.dialog_ok_disabled  = true
        		this.dialog_ok_text 	 = 'Loading...'
        		this.$emit('clicked','Y')
        	}
        }
    }
</script>
