import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Functions from './functions'
import Axios from 'axios'
import ComToolbarDefault from '@/components/com_toolbar_default.vue'
import ComConfirmDefault from '@/components/com_confirm_default.vue'
import ComBottomDefault from '@/components/com_bottom_default.vue'
import ComToolbarCustom from '@/components/com_toolbar_custom.vue'
import ComLogin from '@/components/com_login.vue'
import ComSnackbar from '@/components/com_snackbar.vue'
// Moment JS
import moment from 'moment'

Vue.config.productionTip = false

const plugins = {
  install () {
    Vue.functions = Functions
    Vue.prototype.$functions = Functions
  }
}
Vue.prototype.$axios = Axios
Vue.use(plugins)
Vue.prototype.$moment = moment
Vue.component('app-login',ComLogin)
Vue.component('app-toolbardefault',ComToolbarDefault)
Vue.component('app-confirmdefault',ComConfirmDefault)
Vue.component('app-bottomdefault',ComBottomDefault)
Vue.component('app-toolbarcustom',ComToolbarCustom)
Vue.component('app-snackbar',ComSnackbar)

require('../src/assets/css/my.css')

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
