<template>
    <div>
    <app-toolbarback app_title="Jadwal Sholat"></app-toolbarback>
    <v-card>
        <v-container
            class="pa-0"
            fluid
            dark
        >
            <v-layout row wrap>
            <v-flex xs12>
                <v-card tile color="primary" class="white--text">
                <v-card-title primary-title>
                    <div>
                    <div class="title">{{today}}</div>
                    <div class="heading" v-if="address">{{address}} </div>
                    <v-btn v-if="address" class="orange" round @click="ChangeLocation">Refresh Lokasi</v-btn>
                    </div>
                </v-card-title>
                </v-card>
            </v-flex>
            </v-layout>
        </v-container>
    </v-card>
    <v-layout row>
        <v-flex>
            <v-card flat>
                <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
                <span v-if="posts.length > 0">
                <template v-for="(item, index) in posts">
                    <v-card :key="item.date.readable" :class="dates === item.date.gregorian.day ? 'primary white--text' : ''">
                      <v-layout>
                        <v-flex xs4>
                           <v-btn fab dark small :color="index % 2==0 ? 'primary' : 'orange'">
                            {{item.date.gregorian.day}}
                            </v-btn>
                        </v-flex>
                        <v-flex xs8>
                          <v-card-title primary-title v-if="dates === item.date.gregorian.day" id="todaylist">
                              <div class="headline">{{item.date.readable}} (Hari ini)</div>
                              <div>Fajr : {{ item.timings.Fajr }}{{RemainingTime(item.timings.Imsak, item.timings.Fajr)}}</div>
                              <div>Dhuhr : {{ item.timings.Dhuhr }}{{RemainingTime(item.timings.Fajr, item.timings.Dhuhr)}}</div>
                              <div>Asr : {{ item.timings.Asr }}{{RemainingTime(item.timings.Dhuhr ,item.timings.Asr)}}</div>
                              <div>Maghrib : {{ item.timings.Maghrib }}{{RemainingTime(item.timings.Asr, item.timings.Maghrib)}}</div>
                              <div>Isha : {{ item.timings.Isha }}{{RemainingTime(item.timings.Maghrib, item.timings.Isha)}}</div>
                              <div>Imsak : {{ item.timings.Imsak }}{{RemainingTime(item.timings.Isha, item.timings.Imsak)}}</div>
                          </v-card-title>
                          <v-card-title primary-title v-else>
                              <div class="headline">{{item.date.readable}}</div>
                              <div>Fajr : {{ item.timings.Fajr }}</div>
                              <div>Dhuhr : {{ item.timings.Dhuhr }}</div>
                              <div>Asr : {{ item.timings.Asr }}</div>
                              <div>Maghrib : {{ item.timings.Maghrib }}</div>
                              <div>Isha : {{ item.timings.Isha }}</div>
                              <div>Imsak : {{ item.timings.Imsak }}</div>
                          </v-card-title>
                        </v-flex>
                      </v-layout>
                      <v-divider light></v-divider>
                    </v-card>
                    <v-divider v-if="index + 1 < posts.length" :key="index"></v-divider>
                </template>
                </span>
                <br>
                <br>
            </v-card>
        </v-flex>
    </v-layout>
    <v-snackbar :timeout="snackbar_timeout" v-model="snackbar_dialog">
        {{ snackbar_text }}
    </v-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            loading: false,
            today: '',
            dates: '',
            posts: [],
            address: '',
            snackbar_dialog: false,
            snackbar_text :'',
            snackbar_timeout :3000,
        }),
        created () {
            this.LocationAccess()
        },
        methods: {
            LocationAccess () {
                this.loading = true
                var d = new Date()
                this.dates = ("0" + d.getDate()).slice(-2)
                // Check Permission Location
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.Position)
                } else { 
                    alert('Akses Location tidak disetujui oleh anda')
                }
                this.loading = false
            },
            // Function to Get Position
            Position (position) {
                let latitude = position.coords.latitude
                let longitude = position.coords.longitude
                // Check Latitude and Longitude
                if (latitude === '' && longitude === '') {
                    alert('Akses Location belum disetujui oleh anda')
                    this.LocationAccess()
                } else {
                    // Check Local Prayers inside Local Storage
                    let local_prayers     = JSON.parse(localStorage.getItem('sharia_prayerstime'))
                    if (local_prayers !== null) {
                        let dates    = new Date()
                        let getmonth = dates.getMonth() + 1
                        if (local_prayers[0].data[0].date.gregorian.month.number !== getmonth) {
                            this.PrayersTime(latitude, longitude)
                        } else {
                            this.posts   = local_prayers[0].data
                            this.address = local_prayers[0].address
                        }
                    } else {
                        this.PrayersTime(latitude,longitude)
                    }
                    setTimeout(function(){
                    var elmnt = document.getElementById('todaylist')
                    elmnt.scrollIntoView()
                    }, 500)
                }
            },
            PrayersTime (latitude, longitude) {
                this.loading = true
                let formData = new FormData()
                formData.append('latitudes', latitude)
                formData.append('longitudes', longitude)
                this.$axios.post(this.$functions.SafeURL('apiListShariaPrayersTimeNew', ''),formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                })
                .then(response => {
                    // Check if Status OK
                    if (response.data[0].code === 200) {
                        this.posts = response.data[0].data
                        this.address = response.data[0].address
                        this.today   = this.$functions.TodayFull()
                        localStorage.setItem('sharia_prayerstime', JSON.stringify(response.data))
                    } else {
                        this.snackbar_dialog = true
                        this.snackbar_text = response.data[0].status
                    }
                    this.loading = false
                })
                .catch(e => {
                    this.snackbar_dialog = true
                    this.snackbar_text = e
                    this.loading = false
                })
            },
            ChangeLocation () {
                this.loading = false
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.Locations)
                } else { 
                    alert('Akses Location tidak disetujui oleh anda')
                }
                this.loading = false
            },
            Locations (position) {
                let latitude = position.coords.latitude
                let longitude = position.coords.longitude
                this.PrayersTime(latitude, longitude)
                setTimeout(function(){
                var elmnt = document.getElementById('todaylist')
                elmnt.scrollIntoView()
                }, 500)
            },
            RemainingTime  (prev , gettime,) {
                let dt3      = prev.substring(0,5)
                let dt1      = gettime.substring(0,5)
                let today    = new Date()
                let hours    = today.getHours() 
                let minute   = ("0" + today.getMinutes()).slice(-2)
                let dt2      = ("0" + hours).slice(-2) +':'+("0" + minute).slice(-2)
                let returns  = ''
                if (dt1 > dt2 && dt2 > dt3) {
                    let diff     = this.SecondsToHMS(this.HmsToSeconds(dt1) - this.HmsToSeconds(dt2))
                    if (diff.substring(0,1) !== '-') {
                        let splits    = diff.split(':')
                        let newhours  = splits[0] 
                        let newminute = splits[1] 
                        if (newhours !== '00') {
                            returns = ' / '+newhours+' jam '+newminute+' menit lagi'
                        } else {
                            returns = ' / '+newminute+' menit lagi'
                        }
                    }
                }
                return returns
            },
            HmsToSeconds(s) {
              var b = s.split(':');
              return b[0]*3600 + b[1]*60 + (+b[2] || 0)
            },
            SecondsToHMS(secs) {
              function z(n){return (n<10?'0':'') + n}
              var sign = secs < 0? '-':''
              secs = Math.abs(secs)
              return sign + z(secs/3600 |0) + ':' + z((secs%3600) / 60 |0)
            }
        }
    }
</script>