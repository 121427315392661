<template>
    <div>
    <app-toolbarcustom app_title="Polis" app_back="Y"></app-toolbarcustom>
    <v-card>
        <v-list two-line>
            <v-subheader></v-subheader>
            <v-list-item
              v-for="item in posts"
              :key="item.icon"
              @click="GoTo(item.url)"
            >
              <v-list-item-avatar>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{item.text}}</v-list-item-title>
                <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
    </div>
</template>
<script>
    export default {
        data: () => ({
            loading: false,
            posts: [
                {icon: 'emoji_people', text: 'Takaful Kecelakaan Diri', description: 'Takaful Kecelakaan	Diri Yakesma', internal:'Y', url:'/policy/pa'}
            ]
        }),
        methods: {
            GoTo (url) {
               this.$router.push(url)
            }
        }
    }
</script>