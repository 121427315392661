<template>
    <v-app-bar
    color="primary"
    dark
    flat
    fixed
    >
        <v-icon v-if="app_back ==='Y'" @click="Back">arrow_back_ios</v-icon>
        {{app_title}}
        <v-spacer></v-spacer>
        <v-btn icon @click="GoTo('report')" v-if="app_back ==='N'">
        <v-icon>dashboard</v-icon>
        </v-btn>
    </v-app-bar>
</template>
<script>
    export default {
        props: ['app_title','app_back'],
        methods: {
            GoTo (url) {
                this.$router.push({name: url})
            },
            Back () {
                this.$router.go(-1)
            }
        }
    }
</script>
