<template>
    <div>
    <app-toolbarcustom app_title="DOA" app_url="sharia" app_icon="clear"></app-toolbarcustom>
    <v-layout row>
        <v-flex>
            <v-card flat>
                <v-text-field v-model="searchvalue" placeholder="Cari DOA" append-icon="search" flat v-on:keyup="ListSearch" class="px-2"></v-text-field>
                <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
                <v-expansion-panel>
                <v-expansion-panel-content v-if="posts.length === 0">
                <div slot="header">No Data Available</div>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-else v-for="item of posts"
                    :key="item.sharia_id">
                <div slot="header">{{item.sharia_title}}</div>
                <v-card flat>
                    <v-card-text class="text-xs-right"><span v-html="item.sharia_arab" class="title"></span><br>{{item.sharia_latin}}<br><br>{{item.sharia_meaning}}</v-card-text>
                </v-card>
                </v-expansion-panel-content>
                <infinite-loading @infinite="infiniteHandler" spinner="waveDots"></infinite-loading>
                </v-expansion-panel>
            </v-card>
        </v-flex>
    </v-layout>
    <v-snackbar :timeout="snackbar_timeout" v-model="snackbar_dialog">
        {{ snackbar_text }}
    </v-snackbar>
    </div>
</template>
<script>
    export default {
        data: () => ({
            loading: false,
            posts: [],
            searchvalue:'',
            limit:10,
            offset: 10,
            fetch:10,
            snackbar_dialog: false,
            snackbar_text :'',
            snackbar_timeout :3000,
        }),
        created () {
            this.List()
        },
        methods: {
            infiniteHandler($state) {
            let formData = new FormData()
            formData.append('offset', this.offset)
            formData.append('fetch', this.fetch)
            formData.append('sharia_category', 'DOA')
            formData.append('datavalue', this.searchvalue)
            this.$axios.post(this.$functions.SafeURL('apiListSharia', 'order_by=sharia_id&order_type=ASC'),formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            }).then(({data}) => {
                if (data.length) {
                this.offset = this.offset + this.fetch;
                this.posts.push(...data);
                $state.loaded();
                } else {
                $state.complete();
                }
            })
            },
            List () {
                this.loading = true
                let formData = new FormData()
                formData.append('limit', this.limit)
                formData.append('sharia_category', 'DOA')
                this.$axios.post(this.$functions.SafeURL('apiListSharia', 'order_by=sharia_id&order_type=ASC'),formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                })
                .then(response => {
                    this.posts = response.data
                    this.loading = false
                })
                .catch(e => {
                    this.snackbar_dialog = true
                    this.snackbar_text = e
                    this.loading = false
                })
            },
            ListSearch () {
                this.loading = true
                let formData = new FormData()
                formData.append('sharia_category', 'DOA')
                formData.append('datavalue', this.searchvalue)
                this.$axios.post(this.$functions.SafeURL('apiListSharia', 'order_by=sharia_id&order_type=ASC'),formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                })
                .then(response => {
                    this.posts = response.data
                    this.loading = false
                })
                .catch(e => {
                    this.snackbar_dialog = true
                    this.snackbar_text = e
                    this.loading = false
                })
            },
            FormatDateTime (date) {
                return this.$functions.FormatDateTime(date)
            }
        }
    }
</script>