<template>
	<div id="account">
		<app-toolbarcustom app_title="Akun"></app-toolbarcustom>
		<v-card flat>
            <v-list three-line>
            	<v-subheader></v-subheader>
		        <v-list-item
		        >
		          <v-list-item-content>
		            <v-list-item-title>{{data.users_name}}</v-list-item-title>
		            <v-list-item-subtitle>{{data.users_email}}</v-list-item-subtitle>
		            <v-list-item-subtitle>{{data.users_title}}</v-list-item-subtitle>
		            <v-list-item-subtitle><v-icon>assignment_ind</v-icon>{{data.users_employeeid}} <v-icon>smartphone</v-icon>{{data.users_mobile_1}}</v-list-item-subtitle>
		          </v-list-item-content>
		          <v-list-item-action>
		             <v-avatar
                      size="75"
                      color="grey lighten-4"
                    >
                      <img :src="data.users_avatar_url" :alt="data.users_name">
                    </v-avatar>
                    <small class="fourth--text">Join: {{$functions.FormatDateFull(data.users_entrydate)}}</small>
		          </v-list-item-action>
		        </v-list-item>
		    </v-list>
		</v-card>
		<v-divider></v-divider>
		<v-card class="mt-3" flat>
            <v-list two-line>
				<template v-for="(row, index) in category">
					<v-list-item
					:key="row.id"
					>
					<v-list-item-avatar>
							<v-icon>{{row.icon}}</v-icon>
						</v-list-item-avatar>
					<v-list-item-content v-if="row.id === 'absence'">
						<v-list-item-title v-if="absence.timein !== '00:00' && absence.timeout ==='00:00'" @click="OpenAbsence('timeout')">IN:({{absence.timein}}) - Absen Pulang</v-list-item-title>
						<v-list-item-title v-else-if="absence.timein === '' && absence.timeout === ''" @click="OpenAbsence('timein')">Absen Datang</v-list-item-title>
						<v-list-item-title v-else>IN: {{absence.timein}} OUT: {{absence.timeout}}</v-list-item-title>
					</v-list-item-content>
					<v-list-item-content v-else>
						<v-list-item-title>{{row.title}}</v-list-item-title>
					</v-list-item-content>
					</v-list-item>
					 <v-divider
						v-if="index < category.length - 1"
						:key="`${index}-divider`"
					></v-divider>
				</template>
		    </v-list>
		</v-card>
		<v-divider></v-divider>
		<v-card class="mt-3" flat>
            <v-list two-line subheader>
                <v-list-item ripple>
                <v-list-item-content  @click="LogOut()">
                    <v-list-item-title class="font-weight-bold primary--text text-center">{{text.logout}}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
		<v-dialog v-model="confirm.dialog" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
                <v-card-text>
                    {{confirm.text}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
                    <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="WriteTodo('event')">{{confirm.ok}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
		<v-dialog v-model="dialog.absence.modal" max-width="490">
            <v-card>
                <v-card-title class="secondary headline white--text">{{dialog.absence.title}}</v-card-title>
                <v-card-text class="mt-3">
                    {{dialog.absence.location}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click.stop="dialog.absence.modal = false">{{dialog.absence.no}}</v-btn>
                    <v-btn id="dialog_absence_true" color="primary" outlined :disabled = "dialog.absence.ok !== 'Absen' ? true:false" @click="EnterAbsence">{{dialog.absence.ok}}</v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
		<app-snackbar :dialog="snackbar.dialog" :color="snackbar.color" :text="snackbar.text" :timeout="snackbar.timeout" @close="snackbar.dialog = false"></app-snackbar>
	</div>
</template>
<script>
export default {
    data: () => ({
    	data: {
			users_id: '',
			users_name: '',
			users_email: '',
			users_title: '',
			users_avatar_url: '',
			users_employeeid: '',
			users_mobile_1: '',
			users_entrydate: '',
		},
		absence: {
			url: '',
			timein: '',
			timeout: '',
		},
		text: {
			logout: 'Logout'
		},
    	category: [],
		dialog: {
			absence: {
				modal: false,
				title: '',
				text: '',
				ok: 'Absen',
				no: 'Batal',
				location: '',
			},
			
		},
		confirm: {
			dialog: false,
			title: '',
			text: '',
			ok: 'OK',
			close: 'Close',
		},
		snackbar: {
			dialog: false,
			color: "primary",
			text: "",
			timeout: 3000,
		},
    }),
    created () {
    	this.Initial()
    },
    methods: {
    	Initial () {
			let usersdetail = JSON.parse(localStorage.getItem('local_tim_usersdetail'))
			this.data.users_name = usersdetail[0].users_name
			this.data.users_email = usersdetail[0].users_email
			this.data.users_title = usersdetail[0].users_title
			this.data.users_avatar_url = usersdetail[0].users_avatar_url
			this.data.users_employeeid = usersdetail[0].users_employeeid
			this.data.users_mobile_1 = usersdetail[0].users_mobile_1
			this.data.users_entrydate = usersdetail[0].users_entrydate
			this.Category()
			this.Absence()
    	},
    	Category () {
    		this.category = [
    			{
    				id: 'absence',
    				title: 'Absen',
    				icon: 'watch',
    			},
    			{
    				id: 'dashboard',
    				title: 'Laporan',
    				icon: 'dashboard',
    			},
    			{
    				id: 'peraturan',
    				title: 'Peraturan Perusahaan',
    				icon: 'info',
    			},
    		]
    	},
		CheckAddress (position) {
			this.dialog.absence.location = 'Loading...'
			let formdata = {
				latitudes: String(position.coords.latitude),
				longitudes: String(position.coords.longitude),
			};
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiGetAddress"), param, {
				headers: {
					"Content-Type": "text/plain; charset=ISO-8859-1",
				},
				})
				.then((response) => {
				let feedback = response.data;
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.dialog.absence.location = feedback[0].address
					} else {
						this.dialog.absence.location = ''
						this.SnackBar(true, "error", feedback[0].feedback, 0)
					}
				} else {
					this.dialog.absence.location = ''
				}
				})
				.catch((e) => {
					this.dialog.absence.location = ''
					this.SnackBar(true, "error", e, 0)
				})
		},
		Absence () {
			let formdata = {
				absence_usersid: this.$functions.UsersID(),
				absence_date: this.$functions.Today(),
			};
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListAbsence"), param, {
				headers: {
					"Content-Type": "text/plain; charset=ISO-8859-1",
				},
				})
				.then((response) => {
				let feedback = response.data;
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.absence.timein = feedback[0].absence_timein
						this.absence.timeout = feedback[0].absence_timeout
					} else {
						this.SnackBar(true, "error", feedback[0].feedback, 0)
					}
				}
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 0)
				})
		},
		LogOut () {
			this.text.logout = 'Loading...'
			let formdata = {
				url_type: "logout",
				users_id: this.$functions.UsersID(),
			};
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteUsers"), param, {
			headers: {
				"Content-Type": "text/plain; charset=ISO-8859-1",
			},
			})
			.then((response) => {
			let feedback = response.data;
			if (feedback.length > 0) {
				if (feedback[0].feedback === "Y") {
					setTimeout(function () {
						localStorage.removeItem("local_tim_users")
						localStorage.removeItem("local_tim_users_detail")
						localStorage.removeItem("local_tim_login")
						window.location.href = "/"
					}, 1000)
				} else {
					this.SnackBar(true, "error", feedback[0].feedback, 0)
				}
			} else {
				this.SnackBar(true, "error", "Logout is Failed", 0)
			}
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
			})
		},
		OpenAbsence (flag) {
			this.absence.url = flag
            this.dialog.absence.modal = true
            if (flag === 'timein') {
                this.dialog.absence.title = 'Absen Datang'
            } else {
                this.dialog.absence.title = 'Absen Pulang'
            }
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.CheckAddress)
            } else { 
                alert('Akses Location tidak disetujui oleh anda')
            }
        },
		EnterAbsence () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.SaveAbsence)
            } else { 
                alert('Akses Location tidak disetujui oleh anda')
                this.dialog.absence.ok = 'Absen'
            }
        },
        SaveAbsence (position) {
            let latitude = position.coords.latitude
            let longitude = position.coords.longitude
            if (latitude === '' && longitude === '') {
            alert('Mohon pastikan GPS anda hidup')
				this.dialog.absence.ok = 'Absen'
            } else {
				this.dialog.absence.ok = 'Loading...'
				this.ProcessAbsence(latitude,longitude)
            }
        },
		ProcessAbsence (latitude, longitude) {
			this.dialog.absence.ok = 'Loading...'
			let formdata = {
				url_type: this.absence.url,
				absence_usersid: this.$functions.UsersID(),
				absence_date: this.$functions.Today(),
				absence_latitude_in: String(latitude),
				absence_longitude_in: String(longitude),
				absence_geolocation_in: this.dialog.absence.location,
				absence_latitude_out: String(latitude),
				absence_longitude_out: String(longitude),
				absence_geolocation_out: this.dialog.absence.location,
			};
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteAbsence"), param, {
			headers: {
				"Content-Type": "text/plain; charset=ISO-8859-1",
			},
			})
			.then((response) => {
			let feedback = response.data;
			if (feedback.length > 0) {
				if (feedback[0].feedback === "Y") {
					this.dialog.absence.modal = false
					this.dialog.absence.ok = 'Absen'
					this.Absence()
				} else {
					this.dialog.absence.ok = 'Absen'
					this.SnackBar(true, "error", feedback[0].feedback, 0)
				}
			} else {
				this.dialog.absence.ok = 'Absen'
				this.SnackBar(true, "error", "Logout is Failed", 0)
			}
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
			})
			
		},
		SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
		},
    }

}
</script>