<template>
    <div>
    <app-toolbarcustom app_title="Takaful Kecelakaan Diri Yakesma" app_back="Y"></app-toolbarcustom>
  <v-stepper v-model="form" style="margin-top:55px">
    <v-stepper-header>
      <v-stepper-step :complete="form > 1" step="1">Form</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="form > 2" step="2">Hitung</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">Hasil</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
       <v-container class="mx-0 px-0">
          <v-form enctype="multipart/form-data" ref="form_step1" lazy-validation>
          <v-layout row wrap class="ml-0">
            <v-flex xs12 sm12>
              <v-text-field
                v-model       = "policy.ktp"
                label         = "Nomor KTP"
                prepend-icon  = "redeem"
                placeholder   = "Masukkan Nomor KTP"
                :rules        = "policy.ktp_rules"
                type          = "tel"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model       = "policy.name"
                label         = "Nama Peserta"
                prepend-icon  = "person"
                placeholder   = "Masukkan Nama Peserta"
                :rules        = "policy.name_rules"
                v-on:keyup    = "Pholder"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-dialog
                ref="birthdate_menu"
                v-model="policy.birthdate_menu"
                :return-value.sync="policy.birthdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value       ="birthdate_value"
                    label         ="Tanggal Lahir"
                    prepend-icon  ="event"
                    readonly
                    v-on          ="on"
                    placeholder   = "Masukkan Tanggal Lahir"
                    :rules        = "policy.birthdate_rules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref           = "birthdate_picker"
                  label         = "Tanggal Lahir"
                  prepend-icon  = "calendar_today"
                  v-model       = "policy.birthdate"
                  :max          = "new Date().toISOString().substr(0, 10)"
                  min           = "1950-01-01"
                  @change       = "SaveDate"
                ></v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs12 sm12>
               <v-text-field
                  v-model       = "policy.occupation_desc"
                  label         = "Pekerjaan"
                  prepend-icon  = "list"
                  placeholder   = "Pilih Pekerjaan"
                  readonly      
                  @click        = "FormSelect('occupation')"
                ></v-text-field>
            </v-flex>
          </v-layout>
          </v-form>
        </v-container>
        <v-btn
          color     = "primary"
          @click    = "form = 2"
          outlined
        >
          Lanjut
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-list style="margin-left:-20px">
            <v-list-item
              v-for="item in contribution"
              :key="item.icon"
            >
              <v-list-item-avatar>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{item.text}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="font-weight-black red--text">
                {{item.description}}
              </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-subheader class="primary--text" @click="expand = !expand">LIHAT DETAIL MANFAAT <v-icon v-if="expand">keyboard_arrow_up</v-icon><v-icon v-else>keyboard_arrow_down</v-icon></v-subheader>
        <template v-if = "expand">
        <v-card
          max-height  = "80px"
          flat
          v-for       ="(item, index) in benefit"
          :key        ="item.id"
          :color      ="index % 2==0 ? 'secondary' : 'orange'"
          class       = "white--text"
          >
          <v-card-title class="subtitle-1" v-html="item.text">
          </v-card-title>
        </v-card>
        </template>
        <br>
        <v-btn
          color     = "primary"
          @click    = "form = 3"
          outlined
        >
          Lanjut
        </v-btn>

        <v-btn 
          text
          @click    = "form = 1"
        >Sebelumnya</v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
       <v-container class="mx-0 px-0">
          <v-form enctype="multipart/form-data" ref="form_step3" lazy-validation>
          <v-layout row wrap class="ml-0">
            <v-flex xs12 sm6>
              <v-text-field
                v-model       = "policy.pholder"
                label         = "Nama Pendaftar"
                prepend-icon  = "person"
                placeholder   = "Masukkan Nama Pendaftar"
                :rules        = "policy.pholder_rules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model       = "policy.mobile"
                label         = "Nomor HP"
                prepend-icon  = "phone_android"
                placeholder   = "Masukkan Nomor HP"
                :rules        = "policy.mobile_rules"
                type          = "tel"
              ></v-text-field>
            </v-flex>
          </v-layout>
          </v-form>
        </v-container>
        <v-btn
          color     = "primary"
          @click    = "Process"
          outlined
        >
          Proses
        </v-btn>
        <v-btn 
          text
          @click    = "form = 2"
        >Sebelumnya</v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
  <v-dialog
    v-model="policy.occupation_dialog"
    scrollable 
    fullscreen 
    persistent 
    :overlay="false"
    transition="dialog-transition"
  >
   <v-card>
     <v-card-actions>
      <span class="font-weight-black">&bull; PEKERJAAN</span>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          outlined
          @click="policy.occupation_dialog = false"
      >
          Tutup
      </v-btn>
      </v-card-actions>
      <template>
      <v-progress-linear indeterminate color="primary" v-if="policy.occupation_loading"></v-progress-linear>
      <v-list ripple>
          <template v-for="(item, index) in policy.occupation_list">
          <v-list-item
            :key="item.gendtab_id"
            @click="FormGet('occupation', item.gendtab_id, item.gendtab_desc)"
          >
            <v-list-item-content>
              <v-list-item-title>{{item.gendtab_desc}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="index + 1 < policy.occupation_list.length"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
      </template>
   </v-card> 
  </v-dialog>
  <v-dialog v-model="confirm.dialog" max-width="490">
      <v-card>
          <v-card-title class="secondary headline white--text">{{confirm.title}}</v-card-title>
          <v-card-text>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" outlined @click.stop="confirm.dialog = false">{{confirm.close}}</v-btn>
              <v-btn id="dialog_confirm_true" color="primary" outlined :disabled = "confirm.ok !== 'OK' ? true:false" @click="ProcessOk">{{confirm.ok}}</v-btn>
          </v-card-actions>
  </v-card>
  </v-dialog>
  <v-snackbar :timeout="snackbar_timeout" v-model="snackbar_dialog">
        {{ snackbar_text }}
    </v-snackbar>
</div>
</template>
<script>
    export default {
        data: () => ({
            form: 1,
            loading: false,
            posts: [
                {icon: 'emoji_people', text: 'Takaful Kecelakaan Diri', description: 'Takaful Kecelakaan	Diri Yakesma', internal:'Y', url:'/help/faq'}
            ],
            policy: {
              ktp: '',
              ktp_rules : [v => !!v || 'Mohon isi Nomor KTP'],
              name: '',
              name_rules: [v => !!v || 'Mohon isi Nama Peserta'],
              birthdate: null,
              birthdate_menu: false,
              birthdate_date: '',
              birthdate_rules: [v => !!v || 'Mohon isi Tanggal Lahir'],
              occupation_id: '',
              occupation_desc: '',
              occupation_rules: [v => !!v || 'Mohon isi Pekerjaan'],
              occupation_dialog: false,
              occupation_list: [],
              occupation_loading: false,
              pholder: '',
              pholder_rules: [v => !!v || 'Mohon isi Nama Pendaftar'],
              mobile: '',
              mobile_rules: [v => !!v || 'Mohon isi Nomor HP']
            },
            benefit: [
              {id: 'MTA', text: 'Meninggal dunia karena kecelakaan<br>IDR 10.000.000'},
              {id: 'MTB', text: 'Cacat tetap karena kecelakaan<br>Maksimum IDR 10.000.000'},
              {id: 'MTC', text: 'Biaya pengobatan karena kecelakaan<br>Maksimum IDR 1.500.000'},
              {id: 'MTD', text: 'Santunan biaya pemakaman<br>meninggal dunia akibat selain<br> kecelakaan IDR 2.000.000'},
              {id: 'MTE', text: 'Santunan biaya ambulance<br>maksimum IDR 1.000.000'},
              {id: 'MTF', text: 'Santunan alat bantu mobilitas<br>akibat kecelakaan maksimum<br>IDR 1.000.000'},
            ],
            contribution: [
              {icon: 'payment', text: 'Kontribusi', description: 'IDR 15.000'}
            ],
            expand: false,
            snackbar_dialog: false,
            snackbar_text :'',
            snackbar_timeout :3000,
            confirm: {
              dialog: false,
              title: '',
              ok: 'OK',
              close: 'TUTUP'
            }
        }),
        watch: {
          birthdate_menu (val) {
            val && setTimeout(() => (this.$refs.birthdate_picker.activePicker = 'YEAR'))
          },
        },
        computed: {
            birthdate_value () {
                return this.policy.birthdate ? this.$moment(this.policy.birthdate).format('LL') : ''
            }
        },
        methods: {
            GoTo (url) {
               this.$router.push(url)
            },
            SaveDate (date) {
              this.$refs.birthdate_menu.save(date)
              this.policy.birthdate_date = this.$moment(date).format('YYYY-MM-DD')
            },
            FormSelect (select) {
                switch (select) {
                    case 'occupation':
                        //Job Occupation
                        this.policy.occupation_loading  = true
                        this.policy.occupation_dialog   = true
                        this.$axios.post(this.$functions.SafeURL('apiListGenTableDetail', `gendtab_actived=Y&gendtab_refid=J02&order_by=gendtab_desc&order_type=ASC`))
                        .then(response => {
                            this.policy.occupation_list    = response.data
                            this.policy.occupation_loading = false
                        })
                        .catch(e => {
                            this.snackbar_dialog           = true
                            this.snackbar_text             = e
                            this.policy.occupation_loading = false
                        })
                    break
                }
            },
            FormGet (select, id, desc) {
               switch (select) {
                    case 'occupation':
                        this.policy.occupation_dialog   = false
                        this.policy.occupation_id       = id
                        this.policy.occupation_desc     = desc
                    break
                }
            },
            Pholder () {
              this.policy.pholder = this.policy.name
            },
            Process () {
              this.confirm.dialog = true
              this.confirm.title  = "Proses?"
              setTimeout(function(){
                document.getElementById('dialog_confirm_true').focus()
              }, 500)
            },
            ProcessOk () {
              this.confirm.ok = 'Loading...'
              let data = this
              setTimeout(function(){
                data.confirm.ok       = 'OK'
                data.confirm.dialog   = false
              }, 5000)
            }
        }
    }
</script>